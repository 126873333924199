import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RootStoreModule } from './state';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { MglTimelineModule } from 'angular-mgl-timeline';

const dbConfig: DBConfig  = {
    name: 'siemens_database',
    version: 2,
    objectStoresMeta: [{
      store: '_favorites',
      storeConfig: { keyPath: 'serialnumber', autoIncrement: true },
      storeSchema: [
        { name: 'serialnumber', keypath: 'serialnumber', options: { unique: false } },
        { name: 'productnumber', keypath: 'serialnumber', options: { unique: false } }
      ]
    },
    {
      store: '_recently',
      storeConfig: { keyPath: 'serialnumber', autoIncrement: true },
      storeSchema: [
        { name: 'serialnumber', keypath: 'serialnumber', options: { unique: false } },
        { name: 'productnumber', keypath: 'serialnumber', options: { unique: false } }
      ]
    }
  ]
  };

  const cookieConfig:NgcCookieConsentConfig = {
    "cookie": {
      "domain": environment.api.cookieDomain
    },
    "position": "bottom-right",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#2e3e4e",
        "text": "#ffffff",
        "link": "#ffffff"
      },
      "button": {
        "background": "#3174af",
        "text": "#ffffff",
        "border": "transparent"
      }
    },
    "type": "info",
    "layout": 'my-custom-layout',
    "layouts": {
    "my-custom-layout": '{{layout}}{{compliance}}'
  },
  "elements":{
    "layout": `
    <h3 style="color: white;">{{header}}</h3>
    <span id="cookieconsent:desc" class="cc-message"> {{message}}
    </span>
    <span class="cc-message">{{message2}} <a target="_blank" href="{{href}}">{{link}}</a>
    </span>
    `,
  },
    "content": {
      "header": 'We value your privacy',
      "message": "By clicking “Accept Cookies”, you agree to the storing of strictly necerssary cookies on your device to enhance site navigation.",
      "message2": "These cookies are essential in order to enable you to move around the website and use its features, such as setting your privacy preferences, logging in or filling in forms. Without these cookies, services requested through usage of our website cannot be properly provided. Strictly necessary cookies do not require consent from the user under applicable law. You may configure your web browser to block strictly necessary cookies, but you might then not be able to use the website’s functionalities as intended.",
      "dismiss": "Accept Cookies",
      "deny": "Refuse cookies",
      "link": "Learn more",
      "href": "https://www.portal.innomotics.com/hub/en/cookie-guidelines",
      "policy": "Cookie Policy"
    }
  };
@NgModule({
    declarations: [AppComponent],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      RootStoreModule,
      CoreModule,
      AppRoutingModule,
      FormsModule,
      NgxIndexedDBModule.forRoot(dbConfig),
      NgcCookieConsentModule.forRoot(cookieConfig),
      EffectsModule.forRoot([]),
      MglTimelineModule
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
