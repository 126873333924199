import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from '@core/guards/login.guard';
import { LogoutGuard } from '@core/guards/logout.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule),
    },
    {
        path: 'main',
        loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule),
    },
    {
        path: 'callback',
        loadChildren: () => import('./pages/callback/callback.module').then((m) => m.CallbackModule),
    },
    {
        path: 'login',
        canActivate: [LoginGuard],
        loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule),
    },
    {
        path: 'logout',
        canActivate: [LogoutGuard],
        loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
