
import { Injectable } from '@angular/core';
import { Recently } from '../../core/model/recently';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class RecentlyDatabaseService {

  constructor(private dbService: NgxIndexedDBService) {
  }

  public get(key: string) {
    this.dbService.getByKey('_recently', key).subscribe((recent) => {
      return recent;
    });  
  }

  public async set(value: Recently) {
      await this.dbService
      .update('_recently', {
        serialnumber: value.serialNumber,
        productnumber: value.productNumber,
      }).toPromise();
  }

  public async getAll(): Promise<any> {
    var response = await this.getData().toPromise();
    return response;
  }

  getData(): Observable<any>{
    return this.dbService.getAll('_recently');
  }

  public remove(key: string){
    this.dbService.deleteByKey('_recently', key);  
  }

  public async removeAll() {
    await this.dbService.clear('_recently').toPromise();
  }
}
