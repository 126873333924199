import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductState, ProductAdapter } from './product.adapter';

const { selectEntities } = ProductAdapter.getSelectors();

const getDataState = createFeatureSelector<ProductState>('product');

export const getResult = createSelector(getDataState, (state: ProductState) => {
    const entities = selectEntities(state);
    return entities[0] ? entities[0] : null;
});
export const getSerialNumber = createSelector(getDataState, (state: ProductState) => state.serialNumber);
export const getProductNumber = createSelector(getDataState, (state: ProductState) => state.productNumber);
export const getVersionHistory = createSelector(getDataState, (state: ProductState) => state.versionhistory);
export const getMachineHistory = createSelector(getDataState, (state: ProductState) => state.machinehistory);