import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api/api.service';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ProductApiService extends ApiService {
    uri = 'machine';
    router: Router;

    getMachine(serialNumber: string, productNumber: string, version: number) {
        const versionPath = version ? `version/${version}` : '';
        const headers = new HttpHeaders({});
        return this.get(
            `${this.uri}/${encodeURIComponent(`${serialNumber}+${productNumber}`)}/${versionPath}`,
            headers
        );
    }
    getMachines(serialNumber: string, productNumber: string) {
        const headers = new HttpHeaders({});
        return this.get(
            `${this.uri}/search?serialnumber=${encodeURIComponent(
                `${serialNumber}`
            )}&manufacturerproductnumber=${encodeURIComponent(`${productNumber}`)}&includetotal=true`,
            headers
        );
    }
    getNextMachines(serialNumber: string, productNumber: string, lastItem: string) {
        const headers = new HttpHeaders({});
        return this.get(
            `${this.uri}/search?serialnumber=${encodeURIComponent(
                `${serialNumber}`
            )}&manufacturerproductnumber=${encodeURIComponent(
                `${productNumber}`
            )}&includetotal=true&lastitem=${lastItem}`,
            headers
        );
    }
    getVersionHistory(serialNumber: string, productNumber: string) {
        const headers = new HttpHeaders({});
        return this.get(
            `${this.uri}/${encodeURIComponent(`${serialNumber}+${productNumber}`)}/versionhistory`,
            headers
        );
    }
    public openMachine(
        machineSerialNumber: string,
        machineProductNumber: string
        ) {
        const SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER = '+';
        const key = `${machineSerialNumber}${SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER}${machineProductNumber}`;
        this.router.navigate(["/main/machine/product/data"], {
          queryParams: {
            machineSerialNumberAndProductNumber: key,
          },
          replaceUrl: true
        });
      }
    getMachineHistory(serialNumber: string, productNumber: string) {
        const headers = new HttpHeaders({});
        return this.get(
            `${this.uri}/${encodeURIComponent(`${serialNumber}+${productNumber}`)}/machinehistory`,
            headers
        );
    }
}
