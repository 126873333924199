export const environment = {
    production: true,
    env: 'Test',
    enableManualLogin: true,
    business: 'Large Drives Applications',
    browserTitle: 'IQ Pinpoint',
    api: {
        domain: 'https://service.sidriveiqtwin-lda-q.siemens.cloud',
        cookieDomain: 'sidriveiqtwin-lda-q.siemens.cloud',
        manageAccountEndpoint: 'https://uss.login-qa.siemens.com/',
        apiService: 'api'
    },
    auth: {
        clientIdWeb: 'TDlSAOyTRDvbh1dQs8GKrp3LuWlNpvO6',
        domain: 'siemens-qa-00030.eu.auth0.com',
        apiId: 'https://custom_api_auth0/v1',
        connection: 'main-tenant-oidc',
        logoutPath: '',
    },
};
